import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import styled from "styled-components"
import Layout from "../components/layout"
import Testimonials from "../components/Testimonials"
import SEO from "../components/seo"
import {
  TimeTable,
  Container,
  Heading,
  Text,
  StyledTitle,
} from "../components/styled/Styled"
import { MEDIA_QUERIES, COLORS } from "../constants"

const ImgContainer = styled(motion.div)`
  width: 40%;
  z-index: -2;
  padding-top: 2rem;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 90%;
    margin: auto;
    padding-top: 2rem;
  }
`

const FullWidthImageContainer = styled(ImgContainer)`
  width: 100%;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;
  }
`

const Section = styled(motion.div)`
  display: flex;
  border-right: 8px solid ${COLORS.BLACK};
  flex-wrap: wrap;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column-reverse;
    border-right: none;
    margin: auto;
  }
`

const Article = styled(motion.div)`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding: 0 3rem;
  text-align: right;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 0 1rem;
    text-align: center;
  }
`
const StyledSubheading = styled(motion.div)`
  margin-right: 1rem;
  text-align: right;
  width: 100%;
  color: ${COLORS.PRIMARY};
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    display: none;
  }
`

const headingVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1,
      type: "spring",
      stiffness: 40,
    },
  },
}

const textVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1.2,
      type: "spring",
      stiffness: 40,
    },
  },
}

const imgVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const articleVar = {
  initial: { opacity: 0, y: "-300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const CoachPage = () => {
  const {
    groepslessen,
    stretch,
    priveles,
    priveles2,
    testimonialAlex,
    testimonialTine,
    testimonialVera,
    testimonialJasper,
    testimonialLien,
    testimonialJennifer,
  } = useStaticQuery(graphql`
    query {
      groepslessen: file(relativePath: { eq: "groeplessen.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      stretch: file(relativePath: { eq: "stretch-anke.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      priveles: file(relativePath: { eq: "priveles.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      priveles2: file(relativePath: { eq: "priveles-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonialAlex: file(relativePath: { eq: "alex-testimonial.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonialLien: file(relativePath: { eq: "lien-testimonial.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonialVera: file(relativePath: { eq: "vera-testimonial.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonialJasper: file(relativePath: { eq: "jasper-testimonial.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonialJennifer: file(
        relativePath: { eq: "jennifer-testimonial.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonialTine: file(relativePath: { eq: "tine-testimonial.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const testimonials = [
    {
      image: testimonialJasper,
      name: "Jasper",
      title: "Dancer / Gym Teacher",
      age: "32",
      text:
        "Because of an old injury I need to be carefull with how I exercise. Anke always asks for feedback and adjusts if I have some trouble (or if the work out was to easy) She also finds different ways to give the same old drills, so they never feel boring. This helps keeping the work out fun and keeps my motivation up. I’m not changing personal coaches anymore!",
    },
    {
      image: testimonialJennifer,
      name: "Jennifer",
      title: "Dancer, Film Production Manager",
      age: "27",
      text:
        "Being my first experience with personal training, it has been great so far! Anke guides you very well with customized exercises and food challenges that work for your goals and needs. She makes sure that everything you want to reach with her program will be done, even if you have a busy personal schedule! Definitely would recommend it!",
    },
    {
      image: testimonialTine,
      name: "Tine",
      title: "Leerkracht LO",
      age: "58",
      text:
        "Anke is 1bom energie en dat straalt ze uit naar ons. Geen enkele les is dezelfde. Soms met, soms zonder materiaal, gaan de oefeningen feilloos over van de ene naar de andere telkens met oog voor zowel fitte als minder fitte mensen of mensen met blessures. Anke heeft de juiste opleiding, ervaring en motiverend vermogen. Door haar is het life-time sporten meer dan een begrip.",
    },
    {
      image: testimonialLien,
      name: "Lien",
      title: "Student / Danser",
      age: "24",
      text:
        "Met de personal training van Anke kan ik mijn trainingen doen waar en wanneer ik wil. Met mijn drukke schema is dit dus ideaal! Ook geeft ze enorm goede tips om gezonder te eten. Met die combo heb ik enorm veel vertrouwen in haar capaciteit om mij te helpen fitter te worden.",
    },
    {
      image: testimonialAlex,
      name: "Alex",
      title: "Student / Dancer",
      age: "22",
      text:
        "What I find great about being coached by Anke is the variety of exercises you get and how she always makes sure you have fun in what you're doing, which keeps you motivated. When we first met up, I explained her my previous injuries, what exercises I love doing and she made sure that my training sessions are safe, efficient, fun and rewarding.",
    },
    {
      image: testimonialVera,
      name: "Vera",
      title: "Deelnemer Total Body Workout / Arts",
      age: "63",
      text:
        "Ik volg al jaren de ‘Total body workout’-lessen bij Anke. Elke les is voor mij een nieuwe uitdaging. Anke stimuleert mij om maximaal te presteren op mijn niveau. Als arts weet ik dat haar kennis van anatomie en fysiologie een garantie is om veilig en gezond te sporten.",
    },
  ]

  return (
    <Layout>
      <SEO title="Personal Trainer" />

      <Container>
        <Section>
          <StyledSubheading>PERSONAL COACH</StyledSubheading>
          <Article initial="initial" animate="animate" variants={articleVar}>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              Total Body Workout
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Total Body Workout is a group class for adults (of all levels) who
              want to train their cardio and muscular strength. The goal is to
              make the body stronger and to increase and maintain your health.
              The age of the people in class variate from 18 – 64 and all
              exercises will be adjusted to your personal fitness level. This
              way everybody has their own, appropriate, physical challenge.
            </Text>
            <FullWidthImageContainer style={{ marginTop: "auto" }}>
              <Img fluid={stretch.childImageSharp.fluid} />
            </FullWidthImageContainer>
          </Article>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={groepslessen.childImageSharp.fluid} />
          </ImgContainer>
        </Section>
        <Section>
          <ImgContainer
            initial="initial"
            animate="animate"
            variants={articleVar}
          >
            <Img fluid={priveles.childImageSharp.fluid} />
          </ImgContainer>
          <Article initial="initial" animate="animate" variants={articleVar}>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              Personal Training
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              As I am a certified Physical Coach with a Master Degree in
              Personal Training and Coaching (UGent), I can help you make a
              training schedule to help you reach your individual physical
              goals. We will have an intake conversation and see how we can make
              a trainingsplan that is adjusted to your personal needs. I can
              help you train in person or make a schedule for you to go train
              yourself.
            </Text>
            <Text initial="initial" animate="animate" variants={textVar}>
              (Also possible in groups for max 4 people with same goal).
            </Text>
            <FullWidthImageContainer style={{ marginTop: "auto" }}>
              <Img fluid={priveles2.childImageSharp.fluid} />
            </FullWidthImageContainer>
          </Article>
        </Section>
        <Testimonials testimonials={testimonials} />
        <StyledTitle style={{ marginBottom: "1rem" }}>
          Time Schedule
        </StyledTitle>
        <TimeTable>
          <thead>
            <tr>
              <th>Day of the week</th>
              <th>Time & Class</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Monday</p>
              </td>
              <td>
                <p>
                  10:00 – 18:00 - Available for personal training / group
                  classes
                </p>
              </td>
              <td>
                <p>Flexible location</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Wednesday</p>
              </td>

              <td>
                <p>13:00 - 14:30 - Kids Gym( 4- 6j)</p>
                <p>14:30 – 15:30 - Toddler Gym (2,5 – 4j)</p>
              </td>
              <td>
                <p>
                  <a target="__blank" href="http://www.gvgent.be/">
                    GvGent, Gent
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Thursday</p>
              </td>

              <td>
                <p>17:00- 18:30 - Basic Gymnastics ( 8- 12j)</p>
                <p>
                  20:15 – 21:15 - Total body workout – group class (18+) ( 8-
                  12j)
                </p>
              </td>
              <td>
                <p>
                  <a target="__blank" href="http://www.gvgent.be/">
                    GvGent, Gent
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Friday</p>
              </td>

              <td>
                <p>
                  10:00 - 17:00 - Available for personal training / group
                  classes
                </p>
              </td>
              <td>
                <p>Flexible location</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Saturday</p>
              </td>

              <td>
                <p>
                  14:00 - 22:00 - Available for personal training / group
                  classes
                </p>
              </td>
              <td>
                <p>Flexible location</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Sunday</p>
              </td>

              <td>
                <p>
                  15:00 - 18:00 - Available for personal training / group
                  classes
                </p>
              </td>
              <td>
                <p>Flexible location</p>
              </td>
            </tr>
          </tbody>
        </TimeTable>
      </Container>
    </Layout>
  )
}

export default CoachPage
