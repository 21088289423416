import React from "react"
import { Heading, Text } from "./styled/Styled"
import Img from "gatsby-image"
import styled from "styled-components"
import { MEDIA_QUERIES, COLORS } from "../constants"
import { useMediaQuery } from "react-responsive"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const TestimonialContainer = styled.div`
  width: 100%;
  border-left: 8px solid ${COLORS.BLACK};
  > h2 {
    margin-left: 3rem;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    > div {
      margin-left: 2rem;
    }

    > h2 {
      margin-left: 2rem;
    }
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    flex-direction: column;

    > div {
      margin-left: 1rem;
    }

    > h2 {
      margin-left: 1rem;
    }
  }
`

const Image = styled(Img)`
  width: 100%;
`

const Testimonial = styled.div`
  padding: 0 0.8rem;
  &:focus {
    outline: 0;
    outline: none;
  }
  > div {
    position: relative;
    margin-bottom: 2rem;
    box-shadow: 7px 10px 19px -13px rgba(0, 0, 0, 0.75);

    .info {
      padding: 1.5rem 1rem 0.5rem 1rem;
      height: 100%;

      div {
        display: flex;
        border-top: solid 2px ${COLORS.BLACK};
        padding-top: 0.5rem;
        p {
          color: ${COLORS.PRIMARY};
          text-transform: uppercase;
          font-weight: 600;
          text-align: right;
        }

        .title {
          font-size: 0.6rem;
        }

        .name {
          margin-left: auto;
        }

        .age {
          font-size: 0.6rem;
          color: ${COLORS.BLACK};
          margin-left: 0.3rem;
        }
      }
    }
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    > div {
      width: 100%;
    }
  }
`

const Testimonials = ({ testimonials }) => {
  const isMediumScreen = useMediaQuery({ query: "(max-device-width: 980px)" })
  const isSmallScreen = useMediaQuery({ query: "(max-device-width: 520px)" })
  return (
    <TestimonialContainer>
      <Heading style={{ width: "100%" }}>Testimonials</Heading>
      <Slider
        speed={500}
        infinite={true}
        dots={false}
        slidesToShow={isSmallScreen ? 1 : isMediumScreen ? 2 : 3}
        centerPadding="40px"
        autoplay={true}
        autoplaySpeed={5000}
        focusOnSelect={false}
        cssEase="ease-out"
        swipeToSlide={true}
      >
        {testimonials.map((testimonial, i) => {
          return (
            <Testimonial key={i}>
              <div>
                <Image fluid={testimonial.image.childImageSharp.fluid} />
                <div className="info">
                  <Text className="description">{testimonial.text}</Text>
                  <div>
                    <Text className="title">{testimonial.title} </Text>
                    <Text className="age"> ({testimonial.age}y)</Text>
                    <Text className="name">{testimonial.name}</Text>
                  </div>
                </div>
              </div>
            </Testimonial>
          )
        })}
      </Slider>
    </TestimonialContainer>
  )
}

export default Testimonials
